import { CircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import RemobilizeDialog from "../dialogs/RemobilizeDialog";
import ImmobilizeDialog from "../dialogs/ImmobilizeDialog";
import ReactSpeedometer from "react-d3-speedometer";
import carRender from "@/assets/images/carRender.png";
import truckRender from "@/assets/images/truckRender.png";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import useSMSGetRecord from "@/hooks/useSMSGetRecord";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

// ---- Selected Truck Card Component ------------------------
export default function SelectedVehicleCard({ selectedVehicle }) {
  const { submitSMSGetRecord: submitSMSGetRecord, loading: loading } =
    useSMSGetRecord();
  const navigate = useNavigate();
  return (
    <div className="bg-card flex flex-col p-4 z-10 h-[95%] w-1/4 shadow-lg rounded-lg absolute right-6 transform -translate-x-1/5 top-1/2 -translate-y-1/2">
      <CardHeader selectedVehicle={selectedVehicle} />
      <div className="font-semibold mt-4">VEHICLE SPEED (KM/H)</div>
      <Speedometer speed={selectedVehicle.speed} />

      <div className="font-semibold mt-4">FUEL LEVEL (LITRES)</div>
      <FuelLevel fuelLevel={selectedVehicle.fuel_level} />

      <div className="font-semibold mt-4">ODOMETER</div>
      <Odometer odometer={selectedVehicle.total_odometer} />

      <div className="font-semibold mt-4">CONNECTION</div>
      <ConnectionDetails
        gsmOperator={selectedVehicle.gsm_operator}
        gsmSignal={selectedVehicle.gsm_signal}
      />

      <div className="justify-end mt-auto flex flex-col space-y-2">
        <div className="flex space-x-4">
          <Button
            className="bg-slate-200  w-1/2 text-slate-600"
            variant="outline"
            onClick={() => {
              submitSMSGetRecord(selectedVehicle.tracker_id);
              window.location.reload();
            }}
          >
            {loading ? <LoadingSpinner /> : "Get Record"}
          </Button>
          {selectedVehicle.vehicle_is_immobilized ? (
            <RemobilizeDialog selectedVehicle={selectedVehicle} />
          ) : (
            <ImmobilizeDialog selectedVehicle={selectedVehicle} />
          )}
        </div>
        <Button
          className="w-full"
          variant="outline"
          onClick={() => {
            navigate(`/fleet/${selectedVehicle.vehicle_id}`);
          }}
        >
          View Additional Details
        </Button>
      </div>
    </div>
  );
}

// ---- Header Components  ------------------------
const imageSource = {
  car: carRender,
  truck: truckRender,
};

function VehicleImage({ vehicleType }) {
  return (
    <div className="ml-auto flex justify-center">
      <img
        className="w-[100%]"
        src={imageSource[vehicleType]}
        alt={vehicleType}
      />
    </div>
  );
}

const iconMap = {
  1: <CircleIcon stroke="none" size="14px" fill="rgb(74 222 128)" />,
  0: <CircleIcon stroke="none" size="14px" fill="rgb(156 163 175)" />,
};

function CardHeader({ selectedVehicle }) {
  return (
    <div className="w-full flex items-center space-x-4">
      <div className="flex-col w-full">
        <div className="font-semibold text-xl">
          {selectedVehicle.registration_number}
        </div>
        <div className="font-light text-sm">{selectedVehicle.time_diff}</div>
        <div className="mt-2 flex items-center space-x-2">
          <div>{iconMap[selectedVehicle.gnss]}</div>
          <div className="text-sm">
            GPS {selectedVehicle.gnss ? "connected" : "offline"}
          </div>
        </div>
      </div>
      <VehicleImage vehicleType={selectedVehicle.vehicle_type} />
    </div>
  );
}

// ---- Speedometer  ------------------------
function Speedometer({ speed }) {
  return (
    <div className="w-full pt-2 flex justify-center bg-slate-50 rounded-lg border">
      <ReactSpeedometer
        height={200}
        maxValue={80}
        value={speed}
        needleColor="rgb(107 114 128)"
        segments={4}
        segmentColors={[
          "rgb(219 234 254)",
          "rgb(191 219 254)",
          "rgb(147 197 253)",
          "rgb(96 165 250)",
        ]}
        textColor="black"
        ringWidth={20}
        currentValueText={speed + " km/h"}
      />
    </div>
  );
}

// ---- Speedometer  ------------------------
function segmentValueFormatter(value) {
  if (value < 175) {
    return "E";
  }
  if (value >= 175 && value < 325) {
    return "";
  }
  if (value >= 325 && value < 375) {
    return "1/2";
  }
  if (value >= 375 && value < 550) {
    return "";
  }
  return "F";
}

function FuelLevel({ fuelLevel }) {
  const fuelColorRanges = [
    "rgb(220 38 38)",
    "rgb(239 68 68)",
    "rgb(248 113 113)",
    "rgb(252 165 165)",
  ];

  return (
    <div className="w-full flex justify-center bg-slate-50 rounded-lg border">
      <ReactSpeedometer
        height={200}
        maxValue={700} // assuming the fuel tank capacity as a percentage
        value={fuelLevel}
        needleColor="rgb(107 114 128)"
        segments={4}
        segmentColors={fuelColorRanges}
        segmentValueFormatter={segmentValueFormatter}
        textColor="black"
        ringWidth={10}
        currentValueText={formatNumberWithCommas(fuelLevel) + " litres"}
        needleTransitionDuration={3000}
        needleTransition="easeElastic"
      />
    </div>
  );
}

// ---- Odometer  ------------------------
function Odometer({ odometer }) {
  return (
    <div className="w-full flex justify-center bg-slate-50 rounded-lg border">
      <p className="font-mono font-bold text-xl">
        {formatNumberWithCommas(odometer / 100)} km
      </p>
    </div>
  );
}

// ---- Connection Details ---------------
const gsmMapping = {
  63901: "Safaricom",
  63902: "Safaricom",
  63903: "Airtel",
  63905: "Airtel",
  63906: "Finserve Africa",
  63907: "Telkom",
  64101: "Airtel",
  64110: "MTN",
  64122: "Airtel",
};

function ConnectionDetails({ gsmOperator, gsmSignal }) {
  // Helper function to generate signal bars
  const renderSignalBars = (signalStrength) => {
    return Array.from({ length: 5 }, (_, i) => (
      <div
        key={i}
        className={`h-4 w-1 ${
          i < signalStrength ? "bg-green-500" : "bg-gray-300"
        } mx-0.5`}
        title={`Signal Strength: ${signalStrength}`}
      ></div>
    ));
  };

  return (
    <div className="w-full py-1 px-2 flex items-center justify-between bg-slate-50 rounded-lg border">
      <div className="font-mono text-sm">
        Connected to {gsmMapping[gsmOperator]}
      </div>
      <div className="flex items-end pr-2">{renderSignalBars(gsmSignal)}</div>
    </div>
  );
}
